













































































































































import useMisc from "@/use/misc";
import useSelectItems from "@/use/selectItems";
import {
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { v4 as uuid } from "uuid";

export default defineComponent({
  components: {
    MEmailEditor: () =>
      import("@/components/molecules/emailEditor/m-email-editor.vue"),
  },

  setup(_, { root }) {
    const { registrationTypeItems } = useSelectItems({ root });
    const { getNumberOfDays, goBack } = useMisc({ root });

    const state = reactive({
      templates: [],
      success: false,
      error: false as boolean | number,
      loading: false,
      loaded: false,
      otherEmail: false,
      polishEditorLoaded: false,
      englishEditorLoaded: false,
      polishTemplateAdded: false,
      englishTemplateAdded: false,
      groups: [],
      eventName: "",
      eventStartDate: "",
      dialog: false,
      requestedDate: "",
      dayBeforeEvent: "",
    });

    const model = reactive<{
      state: boolean;
      condition: number;
      when: number;
      days: number;
      email: string;
      polishTemplate: any;
      polishTitle: string;
      polishEditor: any;
      englishTemplate: any;
      englishEditor: any;
      englishTitle: string;
      group: string;
      tab: any;
    }>({
      state: true,
      condition: 1,
      when: 1,
      days: 1,
      email: "",
      polishTemplate: "",
      polishTitle: "",
      polishEditor: {
        chunks: "",
        design: "",
        html: "",
        options: "",
      },
      englishTemplate: "",
      englishTitle: "",
      englishEditor: {
        chunks: "",
        design: "",
        html: "",
        options: "",
      },
      group: "",
      tab: null,
    });

    const conditionItems = [
      {
        name: root.$tc("event.panel.module.autoMessages.condition.unpaid"),
        value: 1,
      },
    ];

    const whenItems = [
      {
        name: root.$tc(
          "event.panel.module.autoMessages.trigger.afterRegistration"
        ),
        value: 1,
      },
      {
        name: root.$tc(
          "event.panel.module.autoMessages.trigger.beforEventStart"
        ),
        value: 2,
      },
    ];

    //  Start fetch single message data

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`trigger/${root.$route.params.amid}`)
        .then(({ data: { trigger } }) => {
          model.state = trigger.state;
          model.condition = trigger.condition;
          model.when = trigger.when;
          model.days = trigger.days;
          model.email = trigger.email || null;

          model.polishEditor.design = trigger.message.design || undefined;
          model.polishEditor.html = trigger.message.template || undefined;
          model.polishTitle = trigger.message.title || undefined;

          model.englishEditor.design =
            trigger.messageOtherLanguage.design || undefined;
          model.englishEditor.html =
            trigger.messageOtherLanguage.template || undefined;
          model.englishTitle = trigger.messageOtherLanguage.title || undefined;

          model.group = trigger.group?.id || undefined;

          state.otherEmail = trigger.email ? true : false;
          state.polishEditorLoaded = trigger.message.design ? true : false;
          state.englishEditorLoaded = trigger.messageOtherLanguage.design
            ? true
            : false;

          state.loaded = true;
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    onMounted(fetchData);

    const fetchTemplates = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get("message-template", { params: { type: "auto" } })
        .then(({ data: { messageTemplates } }) => {
          state.templates = messageTemplates;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.templates = [];
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchTemplates);

    // Fetch event

    const fetchEvent = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}`)
        .then(({ data: { event } }) => {
          state.eventName = event.name;
          state.eventStartDate = event.startDate;
          const day = new Date(state.eventStartDate);
          const date = new Date(day.getTime() - 24 * 60 * 60 * 1000);
          state.dayBeforeEvent = date.toISOString().split("T")[0];
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    onMounted(fetchEvent);

    const pickDate = (date: string, eventDate: string) => {
      const days = getNumberOfDays(date, eventDate);
      model.days = days;
      state.dialog = false;
    };

    const fetchTemplate = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      if (model.tab == 0) {
        state.polishEditorLoaded = false;
        axiosInstance
          .get(`message-template/${model.polishTemplate}`)
          .then(({ data: { messageTemplate } }) => {
            model.polishEditor.design = messageTemplate?.design || undefined;
            model.polishTitle = messageTemplate?.title || undefined;
            state.polishEditorLoaded = true;
          })
          .catch((error) => console.log(error))
          .finally(() => {
            state.loading = false;
          });
      } else if (model.tab == 1) {
        state.englishEditorLoaded = false;
        axiosInstance
          .get(`message-template/${model.englishTemplate}`)
          .then(({ data: { messageTemplate } }) => {
            model.englishEditor.design = messageTemplate?.design || undefined;
            model.englishTitle = messageTemplate?.title || undefined;
            state.englishEditorLoaded = true;
          })
          .catch((error) => console.log(error))
          .finally(() => {
            state.loading = false;
          });
      }
    };

    watch(() => model.polishTemplate, fetchTemplate);
    watch(() => model.englishTemplate, fetchTemplate);

    watch(
      () => model.days,
      () => {
        if (model.days < 1) {
          model.days = 1;
        } else if (model.days > 365) {
          model.days = 365;
        } else {
          model.days;
        }
      }
    );

    const fetchGroups = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}/participant/group/select`)
        .then(({ data: { groups } }) => {
          state.groups = groups;
        })
        .catch((error) => {
          state.groups = [];
          console.log(error);
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchGroups);

    //  End fetch single message data

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 403:
          return "Brak uprawnień";
        case 404:
          return "Endpointu nie znaleziono";
        case 409:
          return "Komunikat już istnieje";
        case 500:
          return root.$tc("layout.errors.500");
        default:
          return "Wystąpił bład";
      }
    };

    // Start update item

    const onSubmit = async () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const data = {
        condition: model.condition,
        when: model.when,
        days: model.days,
        email: model.email || null,
        state: model.state,
        message: {
          design: model.polishEditor.design || undefined,
          template: model.polishEditor.html || undefined,
          title: model.polishTitle || undefined,
        },
        messageOtherLanguage: {
          design: model.englishEditor.design || undefined,
          template: model.englishEditor.html || undefined,
          title: model.englishTitle || undefined,
        },
        group: model.group || undefined,
        event: root.$route.params.id,
      };

      state.loading = true;

      axiosInstance
        .put(`trigger/${root.$route.params.amid}`, data, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          state.success = true;
          state.error = false;

          model.days = 1;
          model.email = "";
          model.state = true;
          model.polishTemplate = "";
          model.polishTitle = "";
          model.polishEditor = {
            chunks: "",
            design: "",
            html: "",
            options: "",
          };
          model.englishTemplate = "";
          model.englishTitle = "";
          model.englishEditor = {
            chunks: "",
            design: "",
            html: "",
            options: "",
          };
          model.group = "";
          state.polishEditorLoaded = false;
          state.englishEditorLoaded = false;

          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "primary",
            message: root.$tc("panel.event.modules.autoMessages.editSuccess"),
          });

          setTimeout(() => {
            goBack("panel.event.view.autoMessages.list");
          }, 500);
        })
        .catch((error) => {
          state.error = error.response.status;
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "error",
            message: getErrorMessage(state.error as number),
          });
        })
        .finally(() => (state.loading = false));
    };

    // End update item

    watch(
      () => state.otherEmail,
      () => {
        if (state.otherEmail) {
          model.email;
        } else {
          model.email = "";
        }
      }
    );

    const rules: {
      [key: string]: (v: any) => boolean | string;
    } = {
      required: (v) => !!v || `${root.$t("layout.misc.required")}`,
    };

    watch(
      () => model.polishTitle,
      () => {
        if (model.polishTitle?.includes("{{ event.name }}")) {
          model.polishTitle = model.polishTitle.replace(
            "{{ event.name }}",
            state.eventName
          );
        }
      }
    );
    watch(
      () => model.englishTitle,
      () => {
        if (model.englishTitle?.includes("{{ event.name }}")) {
          model.englishTitle = model.englishTitle.replace(
            "{{ event.name }}",
            state.eventName
          );
        }
      }
    );

    return {
      state,
      model,
      conditionItems,
      whenItems,

      onSubmit,
      registrationTypeItems,

      rules,
      pickDate,
    };
  },
});
